<template>
	<div id="app" @click="catchMouseClickAnywhere">
		<AlertTop v-if="store.kalert.shown" v-bind:obj="store.kalert"></AlertTop>

		<LoaderOverlay v-if="store.showLoader"></LoaderOverlay>

		<router-view></router-view>

	</div>
</template>

<script>
import AlertTop from "@/components/AlertTop";
import axios from "axios";
import LoaderOverlay from "@/components/LoaderOverlay";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'App',
	components: {
		LoaderOverlay,
		AlertTop
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			requestedRoute: '',

			routeDoesntNeedLogin: false,
		}
	},
	computed: {
		selectedMarketObs() {
			return this.store.selectionObs.markets;
		},
		selectedSurveyObs() {
			return this.store.selectionObs.surveys;
		},
		selectedStationObs() {
			return this.store.selectionObs.stations;
		},
		selectedDemoObs() {
			return this.store.selectionObs.demographics;
		},
		selectedStatisticObs() {
			return this.store.selectionObs.statistics;
		},
		selectedDaypartObs() {
			return this.store.selectionObs.dayparts
		},
	},
	methods: {
		sesscheck() { //this is called on F5/load
			if(this.requestedRoute !== '/login') { //login handles its own stuff
				let localuserid = window.localStorage.getItem('userid');
				let localtoken = window.localStorage.getItem('usertoken');
				if (localuserid && localuserid.length > 0 && localtoken && localtoken.length > 0) {
					let self = this;
					axios.post(
						"/post/do-sesscheck.php",
						JSON.stringify({
							uid: localuserid,
							token: localtoken,
						})
					).then(function(response) {
						let ret = response.data //response.data is returned info
						if(ret.rruser) { //if this comes back we have logged in successfully
							self.setUser(ret.rruser);
							self.store.usercreds = {id: ret.rruser.id, token: localtoken}
							if(self.$route.path !== '/') {
								self.$router.push('/');
							}
						}
						if(ret.error === 1) {
							self.form1working = false;
							self.showKalert(ret.errob);
							if(self.$route.path !== '/login') {
								self.$router.push('/login');
							}
						}
					}).catch(function(error) {
						console.log(error);
					});
				}
				else { //local tokens do not exist, push to login screen
					if(this.$route.path !== '/login') {
						this.$router.push('/login');
					}
				}
			}
		},
		catchMouseClickAnywhere() {
			if(this.store.mouseClickAnywhere === false) {
				this.store.mouseClickAnywhere = true
				setTimeout(function () {
					this.store.mouseClickAnywhere = false
				}.bind(this), 50)
			}
		},
	},
	mounted() {
		let wo = window.location.pathname
		if(wo.indexOf('/share/') === 0) {
			this.routeDoesntNeedLogin = true
		}

		setTimeout(function() {
			this.requestedRoute = this.$route.path
			if(this.routeDoesntNeedLogin === false) {
				this.sesscheck()
			}
		}.bind(this), 100)

		let campBlank = [] //set the default blank campaign 52 weeks
		for(let i=1; i<=52; i++) {
			campBlank.push({weekNumber: i, nSpots: 0})
		}
		this.store.defaultRfCampaignWeeks = campBlank
		campBlank = [] //set the default blank campaign 52 weeks x 7 days
		for(let i=1; i<=364; i++) {
			campBlank.push({weekNumber: i, nSpots: 0})
		}
		this.store.defaultRfCampaignDays = campBlank
		//handle mouse over / down events
		window.mouseIsDown = 0
		document.body.onmousedown = function() {
			// ++window.mouseIsDown
			window.mouseIsDown = 1
			//console.log(event.button)
		}
		document.body.onmouseup = function() {
			// --window.mouseIsDown
			window.mouseIsDown = 0
		}
		//end mouse down checker
		this.store.tableLayout = this.getDefaultTableLayout()
		this.store.rfConfig = this.getDefaultRFConfig()
		this.store.viewPreferences = this.getDefaultViewPreferences()
	},
	created() {
		//handle incoming GET parameters - there might be 'email' and 'token' vals passed
		this.store.getparams = this.$route.query
		//get the domain url
		this.store.urlroot = window.location.origin //http://localhost:8080, https://platform.audology.app, https://platform.audology.com.au
	},
	watch: {
		$route() {
			//force close alert on page change
			this.closeKalert();
		},
		selectedMarketObs: {
			deep: true,
			handler() {
				if (this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
				let mktids = this.selectedMarketObs.map(a => a.id)
				let temps = []
				//remove any non-market surveys - allow mktid to be null for relative date surveys
				for(let sg of this.store.selectionObs.surveys) {
					let newsobs = sg.survobs.filter(item => mktids.includes(item.mktid) || item.mktid === null)
					if(newsobs.length > 0) {
						sg.survobs = newsobs
						temps.push(sg)
					}
				}
				this.store.selectionObs.surveys = temps
				//remove any non-market stations
				this.store.selectionObs.stations = this.store.selectionObs.stations.filter(item => mktids.includes(item.mktid) || item.mktid === 0)
			}
		},
		selectedSurveyObs: {
			deep: true,
			handler() {
				if (this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
				if(this.store.reportType === 'reachFrequency') this.getRFSurveysGRPs(); //if this is R&F, fetch the GRPs/QtrHrs as required
			}
		},
		selectedStationObs: {
			deep: true,
			handler() {
				if (this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true
			}
		},
		selectedDemoObs: {
			deep: true,
			handler() {
				if (this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
				if(this.store.reportType === 'reachFrequency') {
					if(this.store.selectionObs.demographics.length === 1) {
						this.store.rfScheduleDemoId = this.store.selectionObs.demographics[0].id
					}
					//this.getRFSurveysGRPs() //if this is R&F, fetch the GRPs/QtrHrs as required
				}
			}
		},
		selectedStatisticObs: {
			deep: true,
			handler() {
				if (this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
			}
		},
		selectedDaypartObs: {
			deep: true,
			handler() {
				if (this.store.calcIsRunning === false) this.store.selectionsHaveChangedNotYetRun = true;
			}
		},
		'store.rfScheduleDemoId': {
			handler() {
				//if(this.store.reportType === 'reachFrequency') this.getRFSurveysGRPs(); //if this is R&F, fetch the GRPs/QtrHrs as required
			}
		},
		'store.rfConfig.schedule': {
			deep: true,
			handler() {
				this.calculateRfScheduleSummary()
			}
		},
	},
}
</script>

<style>
#app {

}
</style>


