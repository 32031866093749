<template>
	<div style="width: 100%; max-width: 1200px;">
<!--		<QueryErrorNote v-if="store.lookupQueryFailed === true"></QueryErrorNote>-->
		<div>
			<div class="flex-holder">
				<div style="margin-bottom: 20px;">
					<div style="margin-bottom: 20px;">
						<select v-model="chosenSurveyId" class="selectbox">
							<option v-for="surv in surveyOptions" :key="surv.id" :value="surv.id">{{surv.name}}</option>
						</select>
					</div>

					<div style="margin-bottom: 10px;">
						<span class="media-title">Media 1</span>
						<select v-model="selection1.stationId" class="selectbox">
							<option v-for="st in stationOptions" :key="st.id" :value="st.id">{{st.name}}</option>
						</select>
						<select v-model="selection1.demoId" class="selectbox">
							<option v-for="dem in demoOptions" :key="dem.id" :value="dem.id">{{dem.name}}</option>
						</select>
						<select v-model="selection1.daypartId" class="selectbox">
							<option v-for="dp in daypartOptions" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
						<select v-model="selection1.radioTypeId" class="selectbox">
							<option v-for="rt in radioTypeOptions" :key="rt.id" :value="rt.id">{{rt.name}}</option>
						</select>
						<select v-model="selection1.polId" class="selectbox">
							<option v-for="pol in placeOptions" :key="pol.id" :value="pol.id">{{pol.name}}</option>
						</select>
					</div>
					<div style="margin-bottom: 10px;">
						<span class="media-title">Media 2</span>
						<select v-model="selection2.stationId" class="selectbox">
							<option v-for="st in stationOptions" :key="st.id" :value="st.id">{{st.name}}</option>
						</select>
						<select v-model="selection2.demoId" class="selectbox">
							<option v-for="dem in demoOptions" :key="dem.id" :value="dem.id">{{dem.name}}</option>
						</select>
						<select v-model="selection2.daypartId" class="selectbox">
							<option v-for="dp in daypartOptions" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
						<select v-model="selection2.radioTypeId" class="selectbox">
							<option v-for="rt in radioTypeOptions" :key="rt.id" :value="rt.id">{{rt.name}}</option>
						</select>
						<select v-model="selection2.polId" class="selectbox">
							<option v-for="pol in placeOptions" :key="pol.id" :value="pol.id">{{pol.name}}</option>
						</select>
					</div>
					<div style="margin-bottom: 10px;">
						<span class="media-title">Media 3</span>
						<select v-model="selection3.stationId" class="selectbox">
							<option v-for="st in stationOptions" :key="st.id" :value="st.id">{{st.name}}</option>
						</select>
						<select v-model="selection3.demoId" class="selectbox">
							<option v-for="dem in demoOptions" :key="dem.id" :value="dem.id">{{dem.name}}</option>
						</select>
						<select v-model="selection3.daypartId" class="selectbox">
							<option v-for="dp in daypartOptions" :key="dp.id" :value="dp.id">{{dp.name}}</option>
						</select>
						<select v-model="selection3.radioTypeId" class="selectbox">
							<option v-for="rt in radioTypeOptions" :key="rt.id" :value="rt.id">{{rt.name}}</option>
						</select>
						<select v-model="selection3.polId" class="selectbox">
							<option v-for="pol in placeOptions" :key="pol.id" :value="pol.id">{{pol.name}}</option>
						</select>
					</div>

				</div>

				<div v-if="store.selectionsHaveChangedNotYetRun === false && numberMediaInResults > 0" class="rtblHolder">
					<p class="detailp">
						Combined cume {{numdisplay(tableSummaryData.totalCume)}} ({{tableSummaryData.totalCumePctOfUe}}% Cume %).
						Shared cume between {{numberMediaInResults}} media is {{numdisplay(tableSummaryData.sharedCume)}}.
					</p>
					<table v-if="numberMediaInResults === 2" class="std-tbl">
						<thead>
							<tr>
								<td>Media</td>
								<td>Cume Reach</td>
								<td>Cume %</td>
								<td>Univ. Est.</td>
								<td>Shared % of Cume</td>
								<td>Excl Cume</td>
								<td>Excl Cume % of Cume</td>
							</tr>
						</thead>
						<tbody>
						<tr v-for="(ri, index) in tableData" :key="index">
							<td class="namecel">{{replaceMediaName(ri.mediaName)}}</td>
							<td>{{numdisplay(ri.totalCume)}}</td>
							<td>{{numdisplayNdp(ri.totalCumePctOfUe,1)}}</td>
							<td>{{numdisplay(ri.totalUe)}}</td>
							<td>{{numdisplayNdp(ri.sharedCumePctofStnCume,1)}}</td>
							<td>{{numdisplay(ri.exclusiveCume)}}</td>
							<td>{{numdisplayNdp(ri.exclusiveCumePctofStnCume,1)}}</td>
						</tr>
						<tr>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr v-for="(ri, index) in tableExtra3Data" :key="index">
							<td class="namecel" v-html="replaceMediaName(ri.mediaName)"></td>
							<td>{{numdisplay(ri.totalCume)}}</td>
							<td>{{numdisplayNdp(ri.totalCumePctOfUe,1)}}</td>
							<td>{{numdisplay(ri.totalUe)}}</td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						</tbody>
					</table>
					<table v-if="numberMediaInResults === 3" class="std-tbl">
						<thead>
						<tr>
							<td class="namecel">Media</td>
							<td>Cume Reach</td>
							<td>Cume %</td>
							<td>Univ. Est.</td>
							<td>Excl Cume</td>
							<td>Excl Cume % of Cume</td>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(ri, index) in tableData" :key="index">
							<td class="namecel">{{replaceMediaName(ri.mediaName)}}</td>
							<td>{{numdisplay(ri.totalCume)}}</td>
							<td>{{numdisplayNdp(ri.totalCumePctOfUe,1)}}</td>
							<td>{{numdisplay(ri.totalUe)}}</td>
							<td>{{numdisplay(ri.exclusiveCume)}}</td>
							<td>{{numdisplayNdp(ri.exclusiveCumePctofStnCume,1)}}</td>
						</tr>
						<tr>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr v-for="(ri, index) in tableExtra3Data" :key="index">
							<td class="namecel" v-html="replaceMediaName(ri.mediaName)"></td>
							<td>{{numdisplay(ri.totalCume)}}</td>
							<td>{{numdisplayNdp(ri.totalCumePctOfUe,1)}}</td>
							<td>{{numdisplay(ri.totalUe)}}</td>
							<td></td>
							<td></td>
						</tr>
						</tbody>
					</table>
				</div>

				<div v-if="store.selectionsHaveChangedNotYetRun === false && store.returnedData && store.returnedData.venn" class="pies-holder">
					<ChartHolderDash :height="600" addclasses=""
						charttitle="Duplication Visual Venn Diagram"
					>
						<template v-slot:default>
							<VennCompareTwo v-if="numberMediaInResults === 2" :venndata="store.returnedData.venn" :venntotals="vennTotals"></VennCompareTwo>
							<VennCompareThree v-if="numberMediaInResults === 3" :venndata="store.returnedData.venn" :venntotals="vennTotals"></VennCompareThree>
						</template>
					</ChartHolderDash>
				</div>

			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
// import QueryErrorNote from "@/components/QueryErrorNote.vue";
import ChartHolderDash from "@/components/charts/ChartHolderDash.vue";
import VennCompareTwo from "@/components/charts/VennCompareTwo.vue";
import VennCompareThree from "@/components/charts/VennCompareThree.vue";

export default {
	name: "DuplicationVennPage",
	components: {
		VennCompareThree,
		ChartHolderDash,
		VennCompareTwo,
		// QueryErrorNote,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		mediaNames() {
			let mn = []
			let mn1 = this.getMediaName(this.selection1)
			if(mn1) mn.push(mn1)
			let mn2 = this.getMediaName(this.selection2)
			if(mn2) mn.push(mn2)
			let mn3 = this.getMediaName(this.selection3)
			if(mn3) mn.push(mn3)
			return mn
		},
		vennTotals() {
			let vt = []
			if(this.numberMediaInResults >= 2) {
				vt.push({total: this.tableData[0].totalCumePct, exclusive: this.tableData[0].exclusiveCumePctofTotal})
				vt.push({total: this.tableData[1].totalCumePct, exclusive: this.tableData[1].exclusiveCumePctofTotal})
				if(this.numberMediaInResults === 3) {
					vt.push({total: this.tableData[2].totalCumePct, exclusive: this.tableData[2].exclusiveCumePctofTotal})
				}
				vt.push({total: 0, exclusive: this.tableSummaryData.sharedCumePctofTotal})
			}
			return vt
		},
		numberMediaInResults() {
			if(this.store.returnedData && this.store.returnedData.results && this.store.returnedData.results.summary.nMedia) {
				return this.store.returnedData.results.summary.nMedia
			}
			return 0
		},
		tableSummaryData() {
			if(this.store.returnedData && this.store.returnedData.results) {
				return this.store.returnedData.results.summary
			}
			return null
		},
		tableData() {
			if(this.store.returnedData && this.store.returnedData.results) {
				let d = []
				let dt = this.store.returnedData.results
				if(dt.media1) d.push(dt.media1)
				if(dt.media2) d.push(dt.media2)
				if(dt.media3) d.push(dt.media3)
				return d
			}
			return null
		},
		tableExtra3Data() {
			if(this.store.returnedData && this.store.returnedData.shareTable) {
				return this.store.returnedData.shareTable
			}
			return null
		},
		chosenSurveyId: {
			get() {
				return this.store.dupeVennSurveyId
			},
			set(value) {
				this.store.dupeVennSurveyId = value
			}
		},
		selection1: {
			get() {
				return this.store.dupeVenn1
			},
			set(value) {
				this.store.dupeVenn1 = value
			}
		},
		selection2: {
			get() {
				return this.store.dupeVenn2
			},
			set(value) {
				this.store.dupeVenn2 = value
			}
		},
		selection3: {
			get() {
				return this.store.dupeVenn3
			},
			set(value) {
				this.store.dupeVenn3 = value
			}
		},
		surveyOptions() {
			let survs = []
			for(let s of this.store.selectionObs.surveys) {
				survs.push(s)
			}
			return survs
		},
		demoOptions() {
			let demos = []
			for(let s of this.store.selectionObs.demographics) {
				demos.push(s)
			}
			return demos
		},
		daypartOptions() {
			let dps = []
			for(let s of this.store.selectionObs.dayparts) {
				dps.push(s)
			}
			return dps
		},
		stationOptions() {
			let st = [{id: null, name: ''}]
			for(let s of this.store.selectionObs.stations) {
				st.push(s)
			}
			st.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			return st
		},
		radioTypeOptions() {
			let rt = []
			for (let at of this.store.audioTypes) {
				if(at.id < 9) rt.push(at)
			}
			return rt
		},
		placeOptions() {
			let pl = []
			for (let at of this.store.places) {
				if(at.id < 9) pl.push(at)
			}
			return pl
		},
	},
	methods: {
		replaceMediaName(instr) {
			if(instr === 'media1') return this.mediaNames[0]
			if(instr === 'media2') return this.mediaNames[1]
			if(instr === 'media3') return this.mediaNames[2]
			instr = instr.replace('media1', this.mediaNames[0])
			instr = instr.replace('media2', this.mediaNames[1])
			instr = instr.replace('media3', this.mediaNames[2])
			instr = instr.replaceAll(' | ', '<br/>')
			return instr
		},
		getMediaName(sel) {
			let n = ''
			if(sel.stationId) {
				let stn = this.store.selectionObs.stations.find(item => item.id === sel.stationId)
				if(stn) n += stn.name
				else return null
			}
			if(sel.demoId) {
				let dem = this.store.selectionObs.demographics.find(item => item.id === sel.demoId)
				if(dem) n += ' / ' + dem.name
			}
			if(sel.daypartId) {
				let dp = this.store.selectionObs.dayparts.find(item => item.id === sel.daypartId)
				if(dp) n += ' / ' + dp.name
			}
			if(sel.radioTypeId) {
				let rt = this.store.audioTypes.find(item => item.id === sel.radioTypeId)
				if(rt) n += ' / ' + rt.name
			}
			if(sel.polId) {
				let pl = this.store.places.find(item => item.id === sel.polId)
				if(pl) n += ' / ' + pl.name
			}
			return n
		},
		selectFirstIfNull() {
			if(this.store.selectionObs.surveys.length > 0 && !this.chosenSurveyId) {
				this.chosenSurveyId = this.store.selectionObs.surveys[0].id
			}
			if(this.store.selectionObs.demographics.length > 0) {
				if(!this.selection1.demoId) this.selection1.demoId = this.store.selectionObs.demographics[0].id
				if(!this.selection2.demoId) this.selection2.demoId = this.store.selectionObs.demographics[0].id
				if(!this.selection3.demoId) this.selection3.demoId = this.store.selectionObs.demographics[0].id
			}
			if(this.store.selectionObs.dayparts.length > 0) {
				if(!this.selection1.daypartId) this.selection1.daypartId = this.store.selectionObs.dayparts[0].id
				if(!this.selection2.daypartId) this.selection2.daypartId = this.store.selectionObs.dayparts[0].id
				if(!this.selection3.daypartId) this.selection3.daypartId = this.store.selectionObs.dayparts[0].id
			}
		},
		replaceStoredMediaNames() {
			if(this.store.returnedData && this.store.returnedData.venn) {
				let dt = this.store.returnedData.venn.data.labels
				for(let i = 0; i < dt.length-1; i++) {
					this.store.returnedData.venn.data.labels[i] = this.replaceMediaName('media' + (i+1))
				}
			}
		},
	},
	watch: {
		'store.returnedData': {
			handler() {
				this.replaceStoredMediaNames()
			}
		},
		selection1: {
			handler() {
				this.store.selectionsHaveChangedNotYetRun = true
			},
			deep: true
		},
		selection2: {
			handler() {
				this.store.selectionsHaveChangedNotYetRun = true
			},
			deep: true
		},
		selection3: {
			handler() {
				this.store.selectionsHaveChangedNotYetRun = true
			},
			deep: true
		},
	},
	mounted() {
		this.selectFirstIfNull()
	}
}
</script>

<style scoped>
.pies-holder {
	margin-top: 30px;
	display: flex;
	width: 1200px;
	gap: 2%;
}
.media-title {
	margin-right: 5px;
}
.namecel {
	max-width: 600px;
	white-space: unset !important;
}
.detailp {
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: bold;
}
</style>